import React from 'react';
import Errors from 'components/Error';
import { useErrorPageView } from 'data/tracking/useErrorPageView';
import { getSupportUrl } from 'lib/url';
import { ApiError } from 'lib/error';
import { usePlan } from 'data/plan/usePlan';
import { useAnalyticsPageView } from 'data/tracking/useAnalyticsPageView';
import { useErrorLog } from 'data/monitoring/useErrorLog';

type GeneralErrorProps = {
  error: ApiError;
  headless?: boolean;
  darkMode?: boolean;
};

export const GeneralError = (props: GeneralErrorProps) => {
  const { error } = props;
  const { planType } = usePlan();

  useAnalyticsPageView(planType, '/error/generic/');
  useErrorPageView('generic', error);
  useErrorLog('GeneralError', error);
  return (
    <Errors.General
      {...props}
      helpLink={getSupportUrl('SUPPORT_ARTICLE_PLAN', planType)}
    />
  );
};

export const HeadlessGeneralError = (props: GeneralErrorProps) => (
  <GeneralError {...props} headless />
);

export default GeneralError;

import React, { ComponentProps, MouseEventHandler } from 'react';
import Page from 'components/Error/Page';
import Type, { MainHeadline } from 'components/Type';
import { PrimaryButton } from 'components/Button/PrimaryButton';
import ButtonWrapper from 'components/Button/Wrapper';
import { useTranslation } from 'data/translation/useTranslation';

type UserHasFailedReverificationProps = ComponentProps<typeof Page> & {
  seePlansUrl: string;
  onSeePlansClick: MouseEventHandler;
};

const UserHasFailedReverification = ({
  seePlansUrl,
  onSeePlansClick,
}: UserHasFailedReverificationProps) => {
  const { t } = useTranslation();

  return (
    <Page id="rejoin-restrictions-apply-error-page">
      <section>
        <MainHeadline>{t('plan_hopping.you_cant_join_this_plan')}</MainHeadline>
        <Type.P>
          {t('join_error.we_couldnt_confirm_you_live_with_plan_manager')}
        </Type.P>
        <Type.P>{t('join_error.or_find_another_plan')}</Type.P>
      </section>
      <ButtonWrapper>
        <PrimaryButton
          href={seePlansUrl}
          onClick={onSeePlansClick}
          aria-label={t('plan_already_full.see_plans')}
        >
          {t('plan_already_full.see_plans')}
        </PrimaryButton>
      </ButtonWrapper>
    </Page>
  );
};

export default UserHasFailedReverification;

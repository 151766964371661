import React, { ComponentProps, MouseEventHandler } from 'react';
import Page from 'components/Error/Page';
import Type, { MainHeadline } from 'components/Type';
import { PLAN_FAMILY, PlanType } from 'lib/plans';
import ButtonWrapper from 'components/Button/Wrapper';
import { useTranslation } from 'data/translation/useTranslation';
import { PrimaryButton } from 'components/Button/PrimaryButton';

type CountryMismatchProps = ComponentProps<typeof Page> & {
  seePlansUrl: string;
  onSeePlansClick: MouseEventHandler;
  planType: PlanType;
};

const CountryMismatch = ({
  planType,
  seePlansUrl,
  onSeePlansClick,
}: CountryMismatchProps) => {
  const { t } = useTranslation();
  return (
    <Page id="country-mismatch-error-page">
      <section>
        <MainHeadline>
          {t('country_mismatch.need_to_live_at_same_address')}
        </MainHeadline>
        <Type.P>
          {planType === PLAN_FAMILY
            ? t('country_mismatch.it_looks_different_country_family')
            : t('country_mismatch.it_looks_different_country_duo')}
        </Type.P>
      </section>
      <ButtonWrapper>
        <PrimaryButton href={seePlansUrl} onClick={onSeePlansClick}>
          {t('view_plans')}
        </PrimaryButton>
      </ButtonWrapper>
    </Page>
  );
};

export default CountryMismatch;

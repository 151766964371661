import { AxiosError } from 'axios';

export type ErrorType = string;
export type ErrorCode = string;

type ApiErrorMeta = {
  status?: number;
  [key: string]: any;
};

export type ApiError = {
  type: ErrorType;
  code: ErrorCode;
  message?: string;
  status?: number;
  detail?: string;
  data?: any;
};

export type OptionalApiError = ApiError | null;

export const UNKNOWN_ERROR: ErrorCode = 'UNKNOWN_ERROR';

export const makeError = (
  type: ErrorType,
  code: ErrorCode,
  meta: ApiErrorMeta = {},
): ApiError => ({
  ...meta,
  type,
  code,
});

export const makeErrorFromApi = (
  type: ErrorType,
  apiResponseError: AxiosError<ApiError>,
): ApiError => {
  const errorCode: ErrorCode =
    apiResponseError.response &&
    typeof apiResponseError.response.data === 'object'
      ? apiResponseError.response.data.code
      : UNKNOWN_ERROR;
  return makeError(type, errorCode, {
    message: apiResponseError.message,
    ...apiResponseError.response,
  });
};

export default makeError;

import React, { ComponentProps } from 'react';
import Page from 'components/Error/Page';
import Type, { MainHeadline } from 'components/Type';
import { getPlanName } from 'lib/i18n';
import { PrimaryButton } from 'components/Button/PrimaryButton';
import ButtonWrapper from 'components/Button/Wrapper';
import * as tokens from 'styles';
import { PlanType } from 'lib/plans';
import { useTranslation } from 'data/translation/useTranslation';

type JoinOwnPlanProps = Pick<ComponentProps<typeof Page>, 'theme'> & {
  planType: PlanType;
  inviteLink: string;
};

const JoinOwnPlan = ({ theme, planType, inviteLink }: JoinOwnPlanProps) => {
  const { t } = useTranslation();

  return (
    <Page theme={theme} id="join-own-plan-error-page">
      <section>
        <MainHeadline>
          {t('join_own_plan.already_part', {
            plan_name: getPlanName(planType, t),
          })}
        </MainHeadline>
        <Type.P color={tokens.gray30}>
          {t('join_own_plan.you_are_plan_manager_v2', {
            plan_name: getPlanName(planType, t),
          })}
        </Type.P>
        <Type.P color={tokens.gray30}>
          {t('join_own_plan.tap_below_to_invite', {
            plan_name: getPlanName(planType, t),
          })}
        </Type.P>
      </section>
      <ButtonWrapper>
        <PrimaryButton
          href={inviteLink}
          aria-label={t('home-hub.duo.invite_someone')}
        >
          {t('home-hub.duo.invite_someone')}
        </PrimaryButton>
      </ButtonWrapper>
    </Page>
  );
};

export default JoinOwnPlan;

import axios from 'axios';

export const FamilyAPI = {
  V1: axios.create({
    baseURL: '/api/family/v1/family/',
  }),
  V2: axios.create({
    baseURL: '/api/family/v2/family/',
  }),
};

export const mastheadApi = axios.create({ baseURL: '/api/masthead/v1/' });
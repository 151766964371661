import React, { ComponentProps } from 'react';
import Page from 'components/Error/Page';
import Type, { MainHeadline } from 'components/Type';
import { PrimaryButton } from 'components/Button/PrimaryButton';
import ButtonWrapper from 'components/Button/Wrapper';
import { PLAN_FAMILY, PlanType } from 'lib/plans';
import { getPlanName } from 'lib/i18n';
import { useTranslation } from 'data/translation/useTranslation';

export const ContentBodyDuo = () => {
  const { t } = useTranslation();

  return (
    <section>
      <MainHeadline>
        {t('plan_already_full.someone_already_joined')}
      </MainHeadline>
      <Type.P>{t('plan_already_full.get_plan_benefits')}</Type.P>
    </section>
  );
};

export const ContentBodyFamily = () => {
  const { t } = useTranslation();

  return (
    <section>
      <MainHeadline>
        {t('plan_already_full.the_plan_you_tried', {
          plan_name: getPlanName(PLAN_FAMILY, t),
        })}
      </MainHeadline>
      <Type.P>{t('plan_already_full.plan_right_for_you')}</Type.P>
    </section>
  );
};

type PlanAlreadyFullProps = Pick<ComponentProps<typeof Page>, 'theme'> & {
  planType: PlanType;
  landingPageLink: string;
};

const PlanAlreadyFull = ({
  planType,
  landingPageLink,
}: PlanAlreadyFullProps) => {
  const { t } = useTranslation();

  return (
    <Page
      id="plan-already-full-error-page"
      data-testid="plan-already-full-error-page"
    >
      {planType === PLAN_FAMILY ? <ContentBodyFamily /> : <ContentBodyDuo />}
      <ButtonWrapper>
        <PrimaryButton
          href={landingPageLink}
          aria-label={t('plan_already_full.see_plans')}
        >
          {t('plan_already_full.see_plans')}
        </PrimaryButton>
      </ButtonWrapper>
    </Page>
  );
};

export default PlanAlreadyFull;

import { useEffect } from 'react';
import { trackPageView } from 'lib/analytics';
import { PlanType } from 'lib/plans';

export const useAnalyticsPageView = (
  planType: PlanType,
  pageWithoutMarket: string,
) =>
  useEffect(() => {
    trackPageView(`/${planType}${pageWithoutMarket}`);
  }, [planType, pageWithoutMarket]);

import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import { media } from 'styles/mixins';
import * as tokens from 'styles';

const Wrapper = styled.div<{ $centered?: boolean }>`
  margin: ${tokens.spacer24} 0 ${tokens.spacer12};
  display: flex;
  flex-direction: column;
  ${props => props.$centered && ` justify-content: center;`}
  ${media.mobileSmUp} {
    flex-direction: row;
  }
`;

type ButtonWrapperProps = ComponentProps<typeof Wrapper> & {
  centered?: boolean;
};

const ButtonWrapper = ({
  children,
  centered,
  ...props
}: ButtonWrapperProps) => (
  <Wrapper $centered={centered} {...props}>
    {children}
  </Wrapper>
);

export default ButtonWrapper;

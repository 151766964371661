import { useEffect } from 'react';
import { logError } from 'lib/semanticMetrics';
import { usePlan } from 'data/plan/usePlan';
import { useRouter } from 'next/router';
import { OptionalApiError } from 'lib/error';
import { useTranslation } from 'data/translation/useTranslation';

type ErrorPageId =
  | 'MemberRemovalPageError'
  | 'GeneralError'
  | 'AudiobooksOfferTransitionError'
  | 'GPBOfferTransitionError';

export const useErrorLog = (pageId: ErrorPageId, error: OptionalApiError) => {
  const { planType } = usePlan();
  const { pathname } = useRouter();
  const { locale } = useTranslation();

  useEffect(() => {
    logError({
      context: 'error-page',
      planType,
      locale,
      page: pageId,
      type: error?.type ?? 'NO_ERROR_TYPE',
      code: error?.code || 'NO_ERROR_CODE',
      message: error?.message || 'NO_MESSAGE',
      path: pathname,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

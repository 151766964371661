import Page from 'components/Error/Page';
import AddressDidntMatch from 'components/Error/AddressDidntMatch';
import PlanAlreadyFull from 'components/Error/PlanAlreadyFull';
import InvitationExpired from 'components/Error/InvitationExpired';
import General from 'components/Error/General';
import JoinOwnPlan from 'components/Error/JoinOwnPlan';
import CountryMismatch from 'components/Error/CountryMismatch';
import PartnerTransition from 'components/Error/PartnerTransition';
import UserHasPlanHopped from 'components/Error/UserHasPlanHopped';
import UserHasFailedReverification from 'components/Error/UserHasFailedReverification';
import RestrictedCountry from 'components/Error/RestrictedCountry';
import MemberRemovalPageError from 'components/Error/MemberRemovalPageError';
import AudiobooksOfferTransition from 'components/Error/AudiobooksOfferTransition';

const errorPages = {
  Page,
  General,
  AddressDidntMatch,
  PlanAlreadyFull,
  InvitationExpired,
  JoinOwnPlan,
  CountryMismatch,
  PartnerTransition,
  UserHasPlanHopped,
  UserHasFailedReverification,
  RestrictedCountry,
  MemberRemovalPageError,
  AudiobooksOfferTransition,
};

export default errorPages;

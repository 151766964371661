import React, { ComponentProps, MouseEventHandler } from 'react';
import Page from 'components/Error/Page';
import Type, { MainHeadline } from 'components/Type';
import { reload } from 'lib/redirect';
import ButtonWrapper from 'components/Button/Wrapper';
import { useTranslation } from 'data/translation/useTranslation';
import { PrimaryButton } from 'components/Button/PrimaryButton';
import { TertiaryButton } from 'components/Button/TertiaryButton';

type GeneralProps = Pick<ComponentProps<typeof Page>, 'theme'> & {
  onRetry?: MouseEventHandler;
  helpLink: string;
  headless?: boolean;
  darkMode?: boolean;
};

const General = ({
  onRetry = reload,
  helpLink,
  theme,
  headless = false,
  darkMode = false,
}: GeneralProps) => {
  const { t } = useTranslation();

  return (
    <Page
      headless={headless}
      theme={theme}
      id="general-error-page"
      darkMode={darkMode}
    >
      <section>
        <MainHeadline>{t('generic_error.there_was_a_problem')}</MainHeadline>
        <Type.P>{t('generic_error.please_try_again_later')}</Type.P>
      </section>
      <ButtonWrapper>
        <PrimaryButton onClick={onRetry}>{t('try_again')}</PrimaryButton>
        <TertiaryButton href={helpLink}>{t('help')}</TertiaryButton>
      </ButtonWrapper>
    </Page>
  );
};

export default General;

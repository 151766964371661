import React, { ComponentProps, MouseEventHandler } from 'react';
import Page from 'components/Error/Page';
import Type, { MainHeadline } from 'components/Type';
import { PrimaryButton } from 'components/Button/PrimaryButton';
import ButtonWrapper from 'components/Button/Wrapper';
import { useTranslation } from 'data/translation/useTranslation';

type UserHasPlanHoppedProps = Pick<ComponentProps<typeof Page>, 'theme'> & {
  seePlansUrl: string;
  onSeePlansClick: MouseEventHandler;
};

const UserHasPlanHopped = ({
  onSeePlansClick,
  seePlansUrl,
}: UserHasPlanHoppedProps) => {
  const { t } = useTranslation();

  return (
    <Page id="user-has-plan-hopped-error-page">
      <section>
        <MainHeadline>{t('plan_hopping.you_cant_join_this_plan')}</MainHeadline>
        <Type.P>{t('plan_hopping.you_can_join_plan_every_12_months')}</Type.P>
        <Type.P>{t('plan_hopping.still_want_to_change_plan')}</Type.P>
      </section>
      <ButtonWrapper>
        <PrimaryButton
          href={seePlansUrl}
          onClick={onSeePlansClick}
          aria-label={t('plan_already_full.see_plans')}
        >
          {t('plan_already_full.see_plans')}
        </PrimaryButton>
      </ButtonWrapper>
    </Page>
  );
};

export default UserHasPlanHopped;

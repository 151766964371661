import React, { MouseEventHandler } from 'react';
import Page from 'components/Error/Page';
import Type, { MainHeadline } from 'components/Type';
import { PrimaryButton } from 'components/Button/PrimaryButton';
import ButtonWrapper from 'components/Button/Wrapper';
import { useTranslation } from 'data/translation/useTranslation';

type MemberRemovalPageErrorProps = {
  onAccept?: MouseEventHandler;
};

export const MemberRemovalPageError = ({
  onAccept,
}: MemberRemovalPageErrorProps) => {
  const { t } = useTranslation();

  return (
    <Page darkMode headless={false} id="member-removal-error-page">
      <section>
        <MainHeadline>{t('generic_error.there_was_a_problem')}</MainHeadline>
        <Type.P>{t('generic_error.please_try_again_later')}</Type.P>
      </section>
      <ButtonWrapper>
        <PrimaryButton onClick={onAccept}>{t('ok')}</PrimaryButton>
      </ButtonWrapper>
    </Page>
  );
};
export default MemberRemovalPageError;

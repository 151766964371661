export const trackPageView = (
  pageWithoutMarket: any,
  title = document.title,
) => {
  if (typeof window === 'undefined') return;

  const googleAnalyticsCallback = window.ga ? window.ga : () => {};

  googleAnalyticsCallback('send', 'pageview', pageWithoutMarket);

  if (!Array.isArray(window.dataLayer)) {
    window.dataLayer = [];
  }
  window.dataLayer.push({
    event: 'virtualPageview',
    virtualPageURL: pageWithoutMarket,
    virtualPageTitle: title,
  });
};

export type EcommerceEventData = {
  transactionId: string;
  revenue: string;
  currencyCode: string;
  products: ProductFields[];
};

type ProductFields = {
  name: string;
  price: string;
  dimension38: string;
  category: string;
  quantity: number;
};
export const trackEcommercePurchase = ({
  transactionId,
  revenue,
  currencyCode,
  products,
}: EcommerceEventData) => {
  if (typeof window === 'undefined') return;

  if (!Array.isArray(window.dataLayer)) {
    window.dataLayer = [];
  }
  window.dataLayer.push({
    ecommerce: {
      currencyCode,
      purchase: {
        actionField: {
          id: transactionId,
          revenue,
        },
        products,
      },
    },
  });
};

import React, { ComponentProps } from 'react';
import Page from 'components/Error/Page';
import Type, { MainHeadline } from 'components/Type';
import { useTranslation } from 'data/translation/useTranslation';

type InvitationExpiredProps = Pick<ComponentProps<typeof Page>, 'theme'>;

const InvitationExpired = ({ theme }: InvitationExpiredProps) => {
  const { t } = useTranslation();

  return (
    <Page
      theme={theme}
      id="invitation-expired-error-page"
      data-testid="invitation-expired-error-page"
    >
      <section>
        <MainHeadline>{t('invitation_expired.link_expired')}</MainHeadline>
        <Type.P>{t('invitation_expired.created_new_link')}</Type.P>
      </section>
    </Page>
  );
};

export default InvitationExpired;

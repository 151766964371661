import React, { ComponentProps, MouseEventHandler } from 'react';
import Page from 'components/Error/Page';
import Type, { MainHeadline } from 'components/Type';
import { PrimaryButton } from 'components/Button/PrimaryButton';
import ButtonWrapper from 'components/Button/Wrapper';
import { useTranslation } from 'data/translation/useTranslation';

type PartnerTransitionProps = Pick<ComponentProps<typeof Page>, 'theme'> & {
  onManageSubscription: MouseEventHandler;
  accountPageLink: string;
};

const PartnerTransition = ({
  onManageSubscription,
  accountPageLink,
}: PartnerTransitionProps) => {
  const { t } = useTranslation();

  return (
    <Page id="partner-transition-error-page">
      <section>
        <MainHeadline>{t('transition.youre_not_eligible')}</MainHeadline>
        <Type.P>{t('transition.subscription_through_partners_v2')}</Type.P>
        <Type.P>{t('transition.cancel_subscription_to_join_v2')}</Type.P>
      </section>
      <ButtonWrapper>
        <PrimaryButton href={accountPageLink} onClick={onManageSubscription}>
          {t('transition.manage_account')}
        </PrimaryButton>
      </ButtonWrapper>
    </Page>
  );
};

export default PartnerTransition;

import React, { MouseEventHandler } from 'react';
import Page from 'components/Error/Page';
import Type, { MainHeadline } from 'components/Type';
import ButtonWrapper from 'components/Button/Wrapper';
import { useTranslation } from 'data/translation/useTranslation';
import { getPlanName } from 'lib/i18n';
import { usePlan } from 'data/plan/usePlan';
import { PrimaryButton } from 'components/Button/PrimaryButton';
import { PLAN_FAMILY } from 'lib/plans';

type AudiobooksOfferTransitionProps = {
  onManageSubscription: MouseEventHandler;
  accountPageLink: string;
  familyJoinSupportArticle: string;
  audiobookAccessPlanLink: string;
};

const AudiobooksOfferTransition = ({
  onManageSubscription,
  accountPageLink,
  familyJoinSupportArticle,
  audiobookAccessPlanLink,
}: AudiobooksOfferTransitionProps) => {
  const { t } = useTranslation();
  const { planType } = usePlan();
  return (
    <Page id="audiobooks-offer-transition-error-page">
      <section>
        <MainHeadline>
          {t('transition.youre_not_eligible_to_join_plan', {
            plan_name: getPlanName(planType, t),
          })}
        </MainHeadline>
        {/* eslint-disable @spotify-internal/wtb-rules/no-danger-styled */}
        <Type.P
          data-testid="you-have-subscription-to-audiobooks"
          target="_blank"
          dangerouslySetInnerHTML={{
            __html: t('audiobooks.you_have_subscription_to_audiobooks_v2', {
              url: audiobookAccessPlanLink,
            }),
          }}
        />
        {/* eslint-disable @spotify-internal/wtb-rules/no-danger-styled */}
        <Type.P
          dangerouslySetInnerHTML={{
            __html: t(
              'audiobooks.note_that_plan_members_dont_have_access_to_catalog',
              {
                plan_name: planType === PLAN_FAMILY ? t('family') : t('duo'),
                url: familyJoinSupportArticle,
              },
            ),
          }}
        />
      </section>
      <ButtonWrapper>
        <PrimaryButton href={accountPageLink} onClick={onManageSubscription}>
          {t('transition.manage_account', {})}
        </PrimaryButton>
      </ButtonWrapper>
    </Page>
  );
};

export default AudiobooksOfferTransition;

import { ButtonTertiary } from '@spotify-internal/encore-web';
import styled from 'styled-components';
import React, { forwardRef } from 'react';

const StyledTertiary = styled(ButtonTertiary)<{
  $alternateFamily?: boolean;
  $alternateDuo?: boolean;
}>`
  color: ${({ $alternateFamily }) => ($alternateFamily ? 'white' : 'initial')};
  cursor: pointer;
`;

export const TertiaryButton = forwardRef(
  (props: React.ComponentProps<typeof StyledTertiary>, ref) => {
    let themeClassName = `home-hub-button-tertiary ${props.className}`;
    if (props.$alternateFamily) {
      themeClassName = `encore-premium-family-alternate-set ${themeClassName}`;
    }
    if (props.$alternateDuo) {
      themeClassName = `encore-premium-duo-alternate-set ${themeClassName}`;
    }
    return <StyledTertiary {...props} className={themeClassName} ref={ref} />;
  },
);

import React from 'react';
import styled from 'styled-components';
import { LoadingIndicator } from '@spotify-internal/encore-web';
import { useTranslation } from 'data/translation/useTranslation';
import Type from 'components/Type';
import * as tokens from 'styles';

const LoaderWrapper = styled.div`
  position: absolute;
  top: calc(50% - 14px);
  width: 100%;
  text-align: center;
`;

const CustomLoader = styled(LoadingIndicator)<{ $darkMode: boolean }>`
  & > circle {
    fill: ${props =>
      props.$darkMode ? props.theme.invertedTextColor : props.theme.textColor};
  }
`;

const Text = styled(Type.P)`
  margin-top: ${tokens.spacer24};
  text-transform: uppercase;
  letter-spacing: 0.1em;
`;

type LoaderProps = {
  loadingText?: string;
  darkMode: boolean;
};

export const Loader = ({ darkMode, loadingText }: LoaderProps) => {
  const { t } = useTranslation();

  return (
    <LoaderWrapper aria-live="polite" aria-label={t('loading_content')}>
      <CustomLoader indicatorSize={LoadingIndicator.lg} $darkMode={darkMode} />
      {loadingText && <Text>{loadingText}</Text>}
    </LoaderWrapper>
  );
};

import React, { MouseEventHandler } from 'react';
import Page from 'components/Error/Page';
import Type, { MainHeadline } from 'components/Type';
import { getPlanName } from 'lib/i18n';
import ButtonWrapper from 'components/Button/Wrapper';
import { PlanType } from 'lib/plans';
import { useTranslation } from 'data/translation/useTranslation';
import { PrimaryButton } from 'components/Button/PrimaryButton';
import { TertiaryButton } from 'components/Button/TertiaryButton';

type AddressDidntMatchProps = {
  onRetry: MouseEventHandler;
  helpLink: string;
  planType: PlanType;
};

const AddressDidntMatch = ({
  onRetry,
  helpLink,
  planType,
}: AddressDidntMatchProps) => {
  const { t } = useTranslation();
  return (
    <Page id="address-didnt-match-error-page">
      <section>
        <MainHeadline>{t('no_match_error.didnt_match')}</MainHeadline>
        <Type.P>
          {t('no_match_error.double_check_address_plan', {
            plan_name: getPlanName(planType, t),
          })}
        </Type.P>
      </section>
      <ButtonWrapper>
        <PrimaryButton onClick={onRetry}>{t('try_again')}</PrimaryButton>
        <TertiaryButton href={helpLink}>{t('help')}</TertiaryButton>
      </ButtonWrapper>
    </Page>
  );
};

export default AddressDidntMatch;

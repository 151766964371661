import React, { ComponentProps, ReactNode } from 'react';
import Layout from 'components/Layout';
import styled from 'styled-components';
import { Theme } from 'lib/theme';

type ErrorPageProps = ComponentProps<typeof Layout> & {
  children?: ReactNode;
  darkMode?: boolean;
  id?: string;
  theme?: Theme;
};

const ErrorPageLayout = styled(Layout)<{ darkMode: boolean }>`
  color: ${props =>
    props.darkMode ? props.theme.invertedTextColor : props.theme.textColor};
  background: ${props =>
    props.darkMode ? props.theme.invertedBackground : props.theme.background};
`;
const ErrorPage = ({
  children,
  centered,
  headless = false,
  darkMode,
  ...props
}: ErrorPageProps) => (
  <ErrorPageLayout
    darkMode={!!darkMode}
    headless={headless}
    centered={centered}
    {...props}
  >
    {children}
  </ErrorPageLayout>
);

export default ErrorPage;

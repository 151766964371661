import React, { ComponentProps, MouseEventHandler } from 'react';
import Page from 'components/Error/Page';
import Type, { MainHeadline } from 'components/Type';
import { PrimaryButton } from 'components/Button/PrimaryButton';
import ButtonWrapper from 'components/Button/Wrapper';
import { useTranslation } from 'data/translation/useTranslation';

type RestrictedCountryProps = Pick<ComponentProps<typeof Page>, 'theme'> & {
  onRetry: MouseEventHandler;
};

const RestrictedCountry = ({ onRetry }: RestrictedCountryProps) => {
  const { t } = useTranslation();
  return (
    <Page id="restricted-country-error-page">
      <section>
        <MainHeadline>{t('generic_error.there_was_a_problem')}</MainHeadline>
        <Type.P>{t('unauthorized_country_error')}</Type.P>
      </section>
      <ButtonWrapper>
        <PrimaryButton onClick={onRetry}>{t('try_again')}</PrimaryButton>
      </ButtonWrapper>
    </Page>
  );
};

export default RestrictedCountry;
